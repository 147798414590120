import _flickity from "flickity";
import _fizzyUiUtils from "fizzy-ui-utils";

var _global = typeof globalThis !== "undefined" ? globalThis : typeof self !== "undefined" ? self : global;

var exports = {};

/*!
 * Flickity asNavFor v2.0.1
 * enable asNavFor for Flickity
 */

/*jshint browser: true, undef: true, unused: true, strict: true*/
(function (window, factory) {
  // universal module definition

  /*jshint strict: false */

  /*globals define, module, require */
  if (exports) {
    // CommonJS
    exports = factory(_flickity, _fizzyUiUtils);
  } else {
    // browser global
    window.Flickity = factory(window.Flickity, window.fizzyUIUtils);
  }
})(window, function factory(Flickity, utils) {
  'use strict'; // -------------------------- asNavFor prototype -------------------------- //
  // Flickity.defaults.asNavFor = null;

  Flickity.createMethods.push("_createAsNavFor");
  var proto = Flickity.prototype;

  proto._createAsNavFor = function () {
    this.on("activate", (this || _global).activateAsNavFor);
    this.on("deactivate", (this || _global).deactivateAsNavFor);
    this.on("destroy", (this || _global).destroyAsNavFor);
    var asNavForOption = (this || _global).options.asNavFor;

    if (!asNavForOption) {
      return;
    } // HACK do async, give time for other flickity to be initalized


    var _this = this || _global;

    setTimeout(function initNavCompanion() {
      _this.setNavCompanion(asNavForOption);
    });
  };

  proto.setNavCompanion = function (elem) {
    elem = utils.getQueryElement(elem);
    var companion = Flickity.data(elem); // stop if no companion or companion is self

    if (!companion || companion == (this || _global)) {
      return;
    }

    (this || _global).navCompanion = companion; // companion select

    var _this = this || _global;

    (this || _global).onNavCompanionSelect = function () {
      _this.navCompanionSelect();
    };

    companion.on("select", (this || _global).onNavCompanionSelect); // click

    this.on("staticClick", (this || _global).onNavStaticClick);
    this.navCompanionSelect(true);
  };

  proto.navCompanionSelect = function (isInstant) {
    if (!(this || _global).navCompanion) {
      return;
    } // select slide that matches first cell of slide


    var selectedCell = (this || _global).navCompanion.selectedCells[0];

    var firstIndex = (this || _global).navCompanion.cells.indexOf(selectedCell);

    var lastIndex = firstIndex + (this || _global).navCompanion.selectedCells.length - 1;
    var selectIndex = Math.floor(lerp(firstIndex, lastIndex, (this || _global).navCompanion.cellAlign));
    this.selectCell(selectIndex, false, isInstant); // set nav selected class

    this.removeNavSelectedElements(); // stop if companion has more cells than this one

    if (selectIndex >= (this || _global).cells.length) {
      return;
    }

    var selectedCells = (this || _global).cells.slice(firstIndex, lastIndex + 1);

    (this || _global).navSelectedElements = selectedCells.map(function (cell) {
      return cell.element;
    });
    this.changeNavSelectedClass("add");
  };

  function lerp(a, b, t) {
    return (b - a) * t + a;
  }

  proto.changeNavSelectedClass = function (method) {
    (this || _global).navSelectedElements.forEach(function (navElem) {
      navElem.classList[method]("is-nav-selected");
    });
  };

  proto.activateAsNavFor = function () {
    this.navCompanionSelect(true);
  };

  proto.removeNavSelectedElements = function () {
    if (!(this || _global).navSelectedElements) {
      return;
    }

    this.changeNavSelectedClass("remove");
    delete (this || _global).navSelectedElements;
  };

  proto.onNavStaticClick = function (event, pointer, cellElement, cellIndex) {
    if (typeof cellIndex == "number") {
      (this || _global).navCompanion.selectCell(cellIndex);
    }
  };

  proto.deactivateAsNavFor = function () {
    this.removeNavSelectedElements();
  };

  proto.destroyAsNavFor = function () {
    if (!(this || _global).navCompanion) {
      return;
    }

    (this || _global).navCompanion.off("select", (this || _global).onNavCompanionSelect);

    this.off("staticClick", (this || _global).onNavStaticClick);
    delete (this || _global).navCompanion;
  }; // -----  ----- //


  return Flickity;
});

export default exports;